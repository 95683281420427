import * as PIXI from "pixi.js";
import FishColorCurve from "./FishColorCurve";
export default class FishColorCurveUI extends PIXI.Container {
    get curve() {
        return this._curve;
    }
    set curve(value) {
        this._curve = value;
    }
    constructor() {
        super();
        this.offset = { x: 50, y: 50 };
        this.curveWin = { x: 300, y: 300 };
        this.curvePointSize = 6;
        this.gradientWidth = 10;
        this.drawCanvas();
        this.curve.on("curveChange", () => {
            this.moveTag();
        });
        this.curve.on("modeChange", () => {
            this.moveTag();
        });
        this.eventMask = new PIXI.Container();
        this.addChild(this.eventMask);
        this.eventMask.hitArea = new PIXI.Rectangle(0, 0, 1000, 1000);
        let isDataChange = false;
        this.eventMask.on("mousemove", (event) => {
            if (this.selectTag == this.startTag) {
                let x = this.offset.x + this.curvePointSize / 2;
                let width = this.curveWin.x - this.curvePointSize;
                let scale = width / 256;
                let local = this.toLocal(event.global);
                let point = this.curve.currCurve.startPoint;
                point.x = (local.x - x) / scale;
                this.curve.currCurve.startPoint = point;
                this.curve.curveChange();
                isDataChange = true;
            }
            else if (this.selectTag == this.endTag) {
                let x = this.offset.x + this.curvePointSize / 2;
                let width = this.curveWin.x - this.curvePointSize;
                let scale = width / 256;
                let local = this.toLocal(event.global);
                let point = this.curve.currCurve.endPoint;
                point.x = (local.x - x) / scale;
                this.curve.currCurve.endPoint = point;
                this.curve.curveChange();
                isDataChange = true;
            }
            else {
            }
        });
        this.eventMask.on("mouseout", (event) => {
            this.eventMask.interactive = false;
            if (this.selectTag) {
                this.selectTag.interactive = true;
                if (isDataChange) {
                    isDataChange = false;
                    this.emit("editorEnd", this.beforeData, this.curve.getCurveData());
                }
            }
            this.selectTag = undefined;
        });
        this.eventMask.on("mouseup", (event) => {
            this.eventMask.interactive = false;
            if (this.selectTag) {
                this.selectTag.interactive = true;
                if (isDataChange) {
                    isDataChange = false;
                    this.emit("editorEnd", this.beforeData, this.curve.getCurveData());
                }
            }
            this.selectTag = undefined;
        });
    }
    // public setScale(width: number, height: number){
    //     this.curveWin.x = width;
    //     this.curveWin.y = height;
    // }
    // public setUIOffset(x: number, y: number){
    //     this.offset.x = x;
    //     this.offset.y = y;
    // }
    drawCanvas() {
        this.drawCurveWin();
        this.drawHorizontalGradient();
        this.drawVerticalGradient();
        this.drawCurve();
        this.drawTag();
        this.moveTag();
    }
    moveTag() {
        let x = this.offset.x + this.curvePointSize / 2;
        let width = this.curveWin.x - this.curvePointSize;
        let scale = width / 256;
        this.startTag.x = x + this.curve.currCurve.startPoint.x * scale;
        this.endTag.x = x + this.curve.currCurve.endPoint.x * scale;
    }
    drawCurve() {
        if (!this.curve) {
            let curve = new FishColorCurve();
            this.addChild(curve.graphic);
            curve.AddEvent();
            this.curve = curve;
        }
        this.curve.graphic.x = this.offset.x + this.curvePointSize / 2;
        this.curve.graphic.y = this.offset.y + this.curvePointSize / 2;
        // this.curve.setLineWidth(this.curveWidth);
        this.curve.setPointSize(this.curvePointSize);
        this.curve.setSize(this.curveWin.x - this.curvePointSize, this.curveWin.y - this.curvePointSize);
        this.curve.setArea(new PIXI.Rectangle(-this.curvePointSize, -this.curvePointSize, this.curveWin.x + this.curvePointSize, this.curveWin.y + this.curvePointSize));
        this.curve.drawCanvas();
    }
    drawCurveWin() {
        if (!this.bg) {
            this.bg = new PIXI.Graphics();
            this.addChild(this.bg);
        }
        let x = this.offset.x;
        let y = this.offset.y;
        let width = 300;
        let height = 300;
        let count = 4;
        let widthStep = width / count;
        let heightStep = height / count;
        this.bg.rect(x, y, width, height);
        this.bg.fill({ color: 0xffffff });
        for (let i = 1; i < count; i++) {
            let y1 = y + heightStep * i;
            this.bg.moveTo(x, y1);
            this.bg.lineTo(x + width, y1);
            let x1 = x + widthStep * i;
            this.bg.moveTo(x1, y);
            this.bg.lineTo(x1, y + height);
        }
        this.bg.stroke({ color: 0xf1f1f1, width: 1 });
        this.bg.rect(x, y, width, height);
        this.bg.stroke({ color: 0x909090, width: 2 });
    }
    drawTag() {
        let width = 20;
        let height = 20;
        let radius = 4;
        if (!this.startTag) {
            this.startTag = new PIXI.Graphics();
            this.addChild(this.startTag);
            this.startTag.y = this.offset.y + this.curveWin.y + this.gradientWidth;
            this.startTag.x = 30;
            this.startTag.interactive = true;
            this.startTag.on("mousedown", (event) => {
                this.selectTag = this.startTag;
                let x = this.offset.x + this.curvePointSize / 2;
                let width = this.curveWin.x - this.curvePointSize;
                let scale = width / 256;
                let local = this.toLocal(event.global);
                let point = this.curve.currCurve.startPoint;
                point.x = (local.x - x) / scale;
                this.curve.currCurve.startPoint = point;
                this.eventMask.interactive = true;
                this.beforeData = this.curve.getCurveData();
            });
        }
        if (!this.endTag) {
            this.endTag = new PIXI.Graphics();
            this.addChild(this.endTag);
            this.endTag.y = this.offset.y + this.curveWin.y + this.gradientWidth;
            this.endTag.x = 60;
            this.endTag.interactive = true;
            this.endTag.on("mousedown", (event) => {
                this.selectTag = this.endTag;
                let x = this.offset.x + this.curvePointSize / 2;
                let width = this.curveWin.x - this.curvePointSize;
                let scale = width / 256;
                let local = this.toLocal(event.global);
                let point = this.curve.currCurve.endPoint;
                point.x = (local.x - x) / scale;
                this.curve.currCurve.endPoint = point;
                this.eventMask.interactive = true;
                this.beforeData = this.curve.getCurveData();
            });
        }
        this.startTag.beginPath();
        this.startTag.moveTo(0, 0);
        this.startTag.lineTo(-width / 2, height / 2);
        this.startTag.lineTo(-width / 2, height - radius);
        this.startTag.arcTo(-width / 2, height, -width / 2 + radius, height, radius);
        this.startTag.lineTo(width / 2 - radius, height);
        this.startTag.arcTo(width / 2, height, width / 2, height - radius, radius);
        this.startTag.lineTo(width / 2, height / 2);
        this.startTag.closePath();
        this.startTag.stroke({ color: 0x424242 });
        this.startTag.fill({ color: 0x525252 });
        this.endTag.beginPath();
        this.endTag.moveTo(0, 0);
        this.endTag.lineTo(-width / 2, height / 2);
        this.endTag.lineTo(-width / 2, height - radius);
        this.endTag.arcTo(-width / 2, height, -width / 2 + radius, height, radius);
        this.endTag.lineTo(width / 2 - radius, height);
        this.endTag.arcTo(width / 2, height, width / 2, height - radius, radius);
        this.endTag.lineTo(width / 2, height / 2);
        this.endTag.closePath();
        this.endTag.stroke({ color: 0xb1b1b1 });
        this.endTag.fill({ color: 0xffffff });
    }
    drawHorizontalGradient() {
        let h = this.gradientWidth;
        let w = this.curveWin.x;
        let x = this.offset.x;
        let y = this.curveWin.y + this.offset.y;
        if (!this.horizontalGradient) {
            this.horizontalGradient = new PIXI.Graphics();
            this.addChild(this.horizontalGradient);
        }
        if (!this.fillHorizontalGradient) {
            this.fillHorizontalGradient = new PIXI.FillGradient(0, 0, 0, 0);
            this.fillHorizontalGradient.addColorStop(0, 0x000000);
            this.fillHorizontalGradient.addColorStop(1, 0xffffff);
        }
        this.horizontalGradient.clear();
        this.fillHorizontalGradient.x1 = w;
        this.horizontalGradient.x = x;
        this.horizontalGradient.y = y;
        this.horizontalGradient.rect(0, 0, w, h).fill(this.fillHorizontalGradient);
    }
    drawVerticalGradient() {
        let h = this.curveWin.y;
        let w = this.gradientWidth;
        let x = -this.gradientWidth + this.offset.x;
        let y = this.offset.y;
        if (!this.verticalGradient) {
            this.verticalGradient = new PIXI.Graphics();
            this.addChild(this.verticalGradient);
        }
        if (!this.fillVerticalGradient) {
            this.fillVerticalGradient = new PIXI.FillGradient(0, 0, 0, 0);
            this.fillVerticalGradient.addColorStop(1, 0x000000);
            this.fillVerticalGradient.addColorStop(0, 0xffffff);
        }
        this.verticalGradient.clear();
        this.fillVerticalGradient.y1 = h;
        this.verticalGradient.x = x;
        this.verticalGradient.y = y;
        this.verticalGradient.rect(0, 0, w, h).fill(this.fillVerticalGradient);
    }
}
