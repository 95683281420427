import * as PIXI from "pixi.js";
import ImageProcessingCanvas from "./Canvas/ImageProcessingCanvas";
import PIXIFishCanvas from "./Canvas/PIXIFishCanvas";
import ImageController from "./Components/ImageController";
import ImageInfo from "./Data/ImageInfo";
import { SYSTEM_READY } from "./Data/FishSystemEventName";
import { FishCurveCanvas } from "./FishCurveCanvas";
import FishUtilMath from "./Utils/FishUtilMath";
export default class FishMain {
    constructor() {
        this.MAX_ERASE_SIZE = 16;
        this.MIN_ERASE_SIZE = 3;
        this.editMode = EditType.None;
        this.eraseSize = 7;
        this.isLoading = false;
        let canvas = document.getElementById("fishCanvas");
        this.events = new PIXI.EventEmitter();
        let that = this;
        this.mainCanvas = new PIXIFishCanvas(this, canvas, () => {
            console.log("完成!!!");
            this.showImage = new ImageController(that.mainCanvas.stage, this);
            this.showImage.setInteractive(false);
            this.addImage(0, "./assets/test5.png");
            this.addImage(1, "./assets/test4.png");
            this.addImage(2, "./assets/test3.png");
            // setTimeout((() => {
            //     this.setShow(2, false);
            // }).bind(this), 1000);
            // setTimeout((() => {
            //     this.setEditerImage(1);
            //     this.eraseImage();
            //     //this.autoProcessing(100, 200);
            // }).bind(this), 2000);
            /*setTimeout((() => {
                this.setEditerImage(0);
            }).bind(this), 5000);
            setTimeout((() => {
                this.exitEditMode();
            }).bind(this), 7000);*/
            window.addEventListener("resize", this.resizeCallback);
            window.addEventListener("createCurveCanvas", this.onCurveInit);
            window.addEventListener("keydown", this.keyDownCallback);
            this.emit(SYSTEM_READY, undefined);
        }, (e) => { console.log("失败: ", e); });
        this.imageList = [];
        this.loadQueue = [];
        this.resizeCallback = ((ev) => {
            this.mainCanvas.resize();
            let isChangeCurve = this.editMode == EditType.ManualProcessing;
            ImageProcessingCanvas.getInstance().imageNormalization(this.imageList, this.mainCanvas, isChangeCurve);
            if (this.editMode == EditType.None) {
                this.updateShow();
            }
            else {
                this.showImage.loadTexture(this.editImage.showImage);
            }
        }).bind(this);
        this.onCurveInit = ((e) => {
            if (!e.curveCanvas) {
                return;
            }
            if (this.curve) {
                this.curve.off("curveChange", this.onCurveChanged, this);
                this.curve.off("curveConfirm", this.onCurveConfirm, this);
                this.curve.off("curveCancel", this.onCurveCancel, this);
                this.curve.off("resetCurveImage", this.onResetCurveImage, this);
                this.curve.off("sharpness", this.onSharpness, this);
            }
            this.curve = e.curveCanvas;
            this.curve.on("curveChange", this.onCurveChanged, this);
            this.curve.on("curveConfirm", this.onCurveConfirm, this);
            this.curve.on("curveCancel", this.onCurveCancel, this);
            this.curve.on("resetCurveImage", this.onResetCurveImage, this);
            this.curve.on("sharpness", this.onSharpness, this);
        }).bind(this);
        this.keyDownCallback = ((ev) => {
            if (ev.key === "[") {
                this.eraseSize--;
                this.eraseSize = this.eraseSize > this.MIN_ERASE_SIZE ? this.eraseSize : this.MIN_ERASE_SIZE;
                this.showCursorCircle(true, this.eraseSize, this.mainCanvas.scale);
            }
            else if (ev.key == "]") {
                this.eraseSize++;
                this.eraseSize = this.eraseSize < this.MAX_ERASE_SIZE ? this.eraseSize : this.MAX_ERASE_SIZE;
                this.showCursorCircle(true, this.eraseSize, this.mainCanvas.scale);
            }
        }).bind(this);
    }
    addImage(id, url) {
        let data = new ImageInfo(id, url);
        this.loadQueue.push(data);
        this.loadImage();
    }
    deleteImage(id) {
        let index = this.imageList.findIndex((item) => { return item.id == id; });
        if (index < 0) {
            return;
        }
        let image = this.imageList[index];
        this.imageList.splice(index, 1);
        if (this.editImage == image) {
            this.editImage = undefined;
        }
        this.updateShow();
    }
    changeLayer(value) {
        let valueArr = [];
        for (let i = 0; i < value.length; i++) {
            let item = this.imageList.find((item) => { return item.id == value[i]; });
            if (item) {
                valueArr.push(item);
            }
            else {
                console.log("找不到id为", value[i], "的图像");
            }
        }
        this.imageList = valueArr;
        this.updateShow();
    }
    setShow(id, value) {
        let image = this.imageList.find((item) => { return item.id == id; });
        if (image) {
            image.isShow = value;
            this.updateShow();
        }
    }
    saveImage() {
        return this.mainCanvas.extractToBase64(this.mainCanvas.pixi.stage, "image/jpeg");
    }
    dispose() {
        window.removeEventListener("resize", this.resizeCallback);
        window.removeEventListener("createCurveCanvas", this.onCurveInit);
        window.removeEventListener("keydown", this.keyDownCallback);
        if (this.curve) {
            this.curve.off("curveChange", this.onCurveChanged, this);
            this.curve.off("curveConfirm", this.onCurveConfirm, this);
            this.curve.off("curveCancel", this.onCurveCancel, this);
            this.curve.off("resetCurveImage", this.onResetCurveImage, this);
            this.curve.off("sharpness", this.onSharpness, this);
        }
        this.curve = undefined;
        this.mainCanvas.pixi.destroy(true);
    }
    setEditerImage(id) {
        let image = this.imageList.find((item) => { return item.id == id; });
        if (!image) {
            this.editImage = undefined;
            this.exitEditMode();
            return;
        }
        this.editImage = image;
        if (this.editMode == EditType.Erase) {
            this.eraseImage();
        }
        else if (this.editMode == EditType.AutoProcessing) {
            this.processingOperate(this.editImage, false);
        }
        else if (this.editMode == EditType.ManualProcessing) {
            this.processingOperate(this.editImage, true);
        }
    }
    eraseImage() {
        if (!this.editImage) {
            return;
        }
        this.showImage.setInteractive(true);
        this.editMode = EditType.Erase;
        this.mainCanvas.canControl = false;
        this.showImage.loadTexture(this.editImage.showImage);
        this.lastErasePos = undefined;
        this.showCursorCircle(true, this.eraseSize, this.mainCanvas.scale);
    }
    autoProcessing(min, max) {
        if (!this.editImage) {
            return;
        }
        this.editMode = EditType.AutoProcessing;
        this.showImage.setInteractive(false);
        this.mainCanvas.canControl = true;
        this.editImage.autoProcessingMinValue = min;
        this.editImage.autoProcessingMaxValue = max;
        this.processingOperate(this.editImage, false);
    }
    manualProcessing() {
        if (!this.editImage) {
            return;
        }
        this.editMode = EditType.ManualProcessing;
        this.showImage.setInteractive(false);
        this.mainCanvas.canControl = true;
        this.editImage.manualProcessingShowValue = this.editImage.manualProcessingSavedValue;
        this.processingOperate(this.editImage, true);
    }
    exitEditMode() {
        this.editMode = EditType.None;
        this.showImage.setInteractive(false);
        this.mainCanvas.canControl = true;
        this.updateShow();
        this.showCursorCircle(false, this.eraseSize, this.mainCanvas.scale);
    }
    colorize(color, alpha) {
        this.mainCanvas.changeColorize(color, alpha);
    }
    on(event, fn, context) {
        this.events.on(event, fn, context);
    }
    off(event, fn, context, once) {
        this.events.off(event, fn, context, once);
    }
    once(event, fn, context) {
        this.events.once(event, fn, context);
    }
    emit(event, args) {
        this.events.emit(event, args);
    }
    eraseOperate(point, isMouseDown) {
        if (!this.editImage) {
            return;
        }
        if (isMouseDown) {
            ImageProcessingCanvas.getInstance().eraseImage(this.editImage, point, this.eraseSize, this.mainCanvas.stage.width, this.mainCanvas.stage.height);
        }
        else {
            for (let i = 0; i <= 1; i += 0.05) {
                ImageProcessingCanvas.getInstance().eraseImage(this.editImage, FishUtilMath.linearInterpolation(point, this.lastErasePos, i), this.eraseSize, this.mainCanvas.stage.width, this.mainCanvas.stage.height);
            }
        }
        this.lastErasePos = point;
        this.showImage.loadTexture(this.editImage.showImage);
    }
    onCanvasScaleChange(value) {
        this.showCursorCircle(true, this.eraseSize, value);
    }
    processingOperate(image, isChangeCurve) {
        ImageProcessingCanvas.getInstance().imageNormalization([image], this.mainCanvas, isChangeCurve);
        this.showImage.loadTexture(image.showImage);
    }
    loadImage() {
        if (this.isLoading) {
            return;
        }
        if (this.loadQueue.length == 0) {
            return;
        }
        this.isLoading = true;
        let data = this.loadQueue.shift();
        let that = this;
        ImageProcessingCanvas.getInstance().getImageData(data.url).then((imageData) => {
            data.orginalImage = imageData;
            that.imageList.push(data);
            ImageProcessingCanvas.getInstance().imageNormalization([data], this.mainCanvas, false);
            that.updateShow();
            that.isLoading = false;
            that.loadImage();
        }).catch((e) => { console.log("图像加载失败：", e); });
    }
    updateShow() {
        let fusionArr = [];
        for (let i = 0; i < this.imageList.length; i++) {
            let item = this.imageList[i];
            if (item.isShow) {
                fusionArr.push(item.showImage);
            }
        }
        let fusionImage = ImageProcessingCanvas.getInstance().imageFusion(fusionArr, this.mainCanvas.stage.width, this.mainCanvas.stage.height);
        this.showImage.loadTexture(fusionImage);
    }
    showCursorCircle(isShow, value, scaleSize) {
        if (!isShow) {
            this.mainCanvas.pixi.canvas.style.cursor = "auto";
            return;
        }
        if (this.editMode != EditType.Erase) {
            return;
        }
        let xmlns = "http://www.w3.org/2000/svg";
        let cursorColor = "rgba(00, 138, 255, 1)";
        let diameter = value * scaleSize;
        let radius = diameter / 2;
        let cursor = `url('data:image/svg+xml;utf8,<svg xmlns="${xmlns}" width="${diameter}" height="${diameter}" viewBox="0 0 ${diameter} ${diameter}"><circle cx="${radius}" cy="${radius}" r="${radius}" fill="${cursorColor}"/></svg>') ${radius} ${radius}, auto`;
        this.mainCanvas.pixi.canvas.style.cursor = cursor;
    }
    onCurveChanged(curve) {
        console.log("onCurveChanged");
        if (!this.editImage || this.editMode != EditType.ManualProcessing) {
            return;
        }
        this.editImage.manualProcessingShowValue = JSON.stringify(curve.getCurveData());
        this.processingOperate(this.editImage, true);
    }
    onCurveConfirm() {
        console.log("onCurveConfirm");
        if (!this.editImage || this.editMode != EditType.ManualProcessing) {
            return;
        }
        this.editImage.manualProcessingSavedValue = this.editImage.manualProcessingShowValue;
        ImageProcessingCanvas.getInstance().imageNormalization(this.imageList, this.mainCanvas, true);
        this.exitEditMode();
    }
    onCurveCancel() {
        console.log("onCurveCancel");
        if (!this.editImage || this.editMode != EditType.ManualProcessing) {
            return;
        }
        this.editImage.manualProcessingShowValue = this.editImage.manualProcessingSavedValue;
        ImageProcessingCanvas.getInstance().imageNormalization(this.imageList, this.mainCanvas, true);
        this.exitEditMode();
    }
    onResetCurveImage() {
        console.log("onResetCurveImage");
        if (!this.editImage || this.editMode != EditType.ManualProcessing) {
            return;
        }
        this.editImage.manualProcessingShowValue = "";
        this.processingOperate(this.editImage, true);
    }
    onSharpness() {
        console.log("onSharpness");
    }
}
var EditType;
(function (EditType) {
    EditType[EditType["None"] = 0] = "None";
    EditType[EditType["Erase"] = 1] = "Erase";
    EditType[EditType["AutoProcessing"] = 2] = "AutoProcessing";
    EditType[EditType["ManualProcessing"] = 3] = "ManualProcessing";
})(EditType || (EditType = {}));
window.FishMain = FishMain;
window.FishCurveCanvas = FishCurveCanvas;
